// import AOS from 'aos';
import 'swiper/swiper.min.css';
import 'swiper/swiper-bundle.min.css';
import Swiper, { Navigation, Pagination, Autoplay, EffectFade , Thumbs, FreeMode } from 'swiper';

Swiper.use([Navigation, Pagination, Autoplay, EffectFade, Thumbs]);
window.Swiper = Swiper;

// JavaScript
$(document).ready( function () {
  // transparent header
  function headerScroll() {
    if ($(window).scrollTop() > 100) {
      $('.header').addClass("min-header");
    } else {
      $('.header').removeClass("min-header");
    }
  }
  headerScroll();
  $(window).on('scroll', function () {
    headerScroll();
  });

  $('.header-mobile-menu').on('click', function () {
    $(this).toggleClass('active');
    $('.header-menu-outer').toggleClass('active');
  });

  const body = $('body');

  // 導覽列選單開關
  $('.menu-link').on('click', function(e) {
      e.stopPropagation();
      $(this).parent().toggleClass('active').siblings().removeClass('active');
  });

  $(document).on('click', function(){
    if($('.dropdown').hasClass('active')) {
      $('.dropdown').removeClass('active');
    }
  })

  const indexBanner = new Swiper('.home-banner-swiper.swiper-container', {
    loop: true,                   // 循環模式
    speed: 400,
    effect: 'fade',
    autoplay: {
      delay: 6000,                // 自動播放延遲時間（毫秒）
      disableOnInteraction: false // 互動後是否停止自動播放
    },
    pagination: {
      el: '.swiper-pagination',    // 分頁元素
      clickable: true,             // 分頁是否可點擊
    },
  });

  // dropdown
  $('.dropdown-btn').on('click', function () {
    const id = $(this).attr('id');
    const dropdown_is_active = $('[aria-labelledby=' + id + ']').hasClass("is-active");
    if (dropdown_is_active >= 1) {
      $('[aria-labelledby=' + id + ']').removeClass('is-active');
      if (window.innerWidth <= 640) {
        $('body').css({
          'height': '',
          'overflow': '',
        })
      }
    } else {
      $(".dropdown-list:not([aria-labelledby=" + id + "]").removeClass('is-active');
      $('[aria-labelledby=' + id + ']').addClass('is-active');
      if (window.innerWidth <= 640) {
        $('body').css({
          'height': '100vh',
          'overflow': 'hidden',
        })
      }
    }
  });

  $(document).click(function (event) {
    if (!$(event.target).closest('.dropdown-btn').length) {
      if ($('.dropdown-list').is(":visible")) {
        $(".dropdown-list").removeClass('is-active');
        if (window.innerWidth <= 640) {
          $('body').css({
            'height': '',
            'overflow': '',
          })
        }
      }
    }
  });

  // 手機版按鈕toggle
  $('#navBtn').on('click', function(){
    let navBtn = $('#navBtn');
    let mbMenu = navBtn.siblings('.header-wrapper');

    if(navBtn.hasClass('is-active')) {
      navBtn.removeClass('is-active');
      mbMenu.addClass('hidden');
      body.removeClass('no-scroll');
    } else {
      navBtn.addClass('is-active');
      mbMenu.removeClass('hidden');
      body.addClass('no-scroll');
    }
  })

  // 設定手機版導覽列按鈕及選單顯示狀態
  function toggleNavDisplay() {
    let navBtn = $('#navBtn');
    let mbMenu = navBtn.siblings('.header-wrapper');
    let windowWidth = window.innerWidth;

    if (windowWidth > 1024) { // 確保 header-wrapper 在寬度 > 1024px 時不隱藏
      if (mbMenu.hasClass('hidden')) {
        mbMenu.removeClass('hidden');
      }
      if (!navBtn.hasClass('is-active')) {
        navBtn.addClass('is-active');
      }
    } else {
      if (!mbMenu.hasClass('hidden')) {
        mbMenu.addClass('hidden');
      }
      if (navBtn.hasClass('is-active')) {
        navBtn.removeClass('is-active');
      }
    }
  }
  $(function(){ //每當載入時重新檢查並更新狀態
    toggleNavDisplay();
  })
  $(window).on('resize', function(){ //每當瀏覽器寬度改變時重新檢查並更新狀態
    toggleNavDisplay();
    body.removeClass('no-scroll');
  });

  // 產品彙整 Swiper
  const productSwiper = new Swiper('.product-swiper', {
    spaceBetween: 20,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      375: {
        slidesPerView: 1,
      },
      640: {
        slidesPerView: 2,
      },
      1024: {
        slidesPerView: 3,
      }
    },
  });

  // 產品內頁圖片Swiper－縮圖
  const $thumbsContainer = $('.product-img-swiper-thumb');
  const thumbSlides = $thumbsContainer.find('.swiper-slide').length; // swiper中的圖片數量

  if(thumbSlides <= 1) { // 如swiper slide 數量小於等於1張，則主Swiper不綁定縮圖
    $thumbsContainer.hide();

    new Swiper('.product-img-swiper', {
      loop: false,
      spaceBetween: 10,
    });

    $('.product-img-swiper').find('.swiper-button-next, .swiper-button-prev, .swiper-pagination').hide(); // 隱藏 nav 按鈕
  } else if (thumbSlides > 1 && thumbSlides < 4) { // 大於1張小於4張
    const colClass = (thumbSlides === 2) ? 'col-50' : 'col-75'; // 判斷，如為2張slide則width:50%; 如為3張則 width: 75%;
    $thumbsContainer.addClass(colClass);

    const productImgSwiperThumb = new Swiper('.product-img-swiper-thumb', {
      loop: true,
      spaceBetween: 12,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      slidesPerView: thumbSlides, // 每次顯示4張縮圖
      watchSlidesProgress: true,
      freeMode: false,
    });
    // 產品內頁圖片Swiper－主圖片
    new Swiper('.product-img-swiper', {
      loop: true,
      spaceBetween: 10,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      thumbs: {
        swiper: productImgSwiperThumb,
      }
    });

  } else { // 大於等於4張
    const productImgSwiperThumb = new Swiper('.product-img-swiper-thumb', {
      loop: true,
      spaceBetween: 12,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      slidesPerView: 4, // 每次顯示4張縮圖
      watchSlidesProgress: true,
      freeMode: false,
    });

    // 產品內頁圖片Swiper－主圖片
    new Swiper('.product-img-swiper', {
      loop: true,
      spaceBetween: 10,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      thumbs: {
        swiper: productImgSwiperThumb,
      }
    });
  }

  // Product-files Tooltip
  $('.tooltip-container > a').on('mouseenter', function(){
    let tooltipText = $(this).text();
    $(this).closest('.tooltip-container').append(`<div class="tooltip">${tooltipText}</div>`);
  }).on('mouseleave', function(){
    $(this).siblings('.tooltip').remove();
  })

  // News Tooltip
  $('.news-tooltip').on('mouseenter', function(){
    let tooltipText = $(this).find('.news-title').text();
    $(this).append(`<div class="tooltip">${tooltipText}</div>`);
  }).on('mouseleave', function(){
    $(this).find('.tooltip').remove();
  })

  // 首頁麵包屑添加class
  $('.breadcrumb-item').each(function() {
    if ($(this).text().trim() === '首頁') {
      $(this).addClass('breadcrumb-home');  // 為"首頁"的麵包屑項目添加類別
    }
  });
});

$(function() {
  // --首頁案例顯示切換
  // 當頁面載入時，檢查並為第一個 .cases-type 添加 .selected 類別
  const firstCaseType = $('.cases-side-bar .cases-type').first();
  firstCaseType.addClass('selected');

  // 根據第一個 .selected 標籤的內容，顯示對應的 .case-main-row
  let selectedTypeName = firstCaseType.text().trim();
  $('.case-main-row').addClass('hidden');
  $(`.case-type-tag:contains('${selectedTypeName}')`).closest('.case-main-row').removeClass('hidden');

  // 點擊事件：點擊時設置相應選項並顯示對應的內容
  $('.cases-type').on('click', function() {
      $('.cases-type.selected').removeClass('selected');
      $(this).addClass('selected');

      let selectedTypeName = $(this).text().trim(); // 獲取當前選中的標籤文字
      $('.case-main-row').addClass('hidden');
      $(`.case-type-tag:contains('${selectedTypeName}')`).closest('.case-main-row').removeClass('hidden');
  });

  // 關於我們 應用實績 swiper
  // 初始化每個 Swiper
  $('.partner-section .case-category-content').each(function() {
    const swiperId = $(this).data('swiper');
    const swiperContainer = $(this).find('.swiper-container');
    const prevButton = $(`[data-swiper-nav="${swiperId}"] .swiper-button-prev`);
    const nextButton = $(`[data-swiper-nav="${swiperId}"] .swiper-button-next`);

    new Swiper(swiperContainer[0], {
      loop: false,
      speed: 400,
      effect: 'slide',
      navigation: {
        nextEl: nextButton[0],
        prevEl: prevButton[0],
      },
    });
  });

  // 關於我們 應用實績 類別切換
  const firstCaseTypeInAbout = $('.cases-tab .cases-type').first();
  firstCaseTypeInAbout.addClass('selected');

  // 根據第一個 .selected 標籤的內容，顯示對應的 .case-main-row
  let selectedCaseTypeName = firstCaseTypeInAbout.text().trim();
  $('.partner-section .case-category-content').addClass('hidden');
  $(`.partner-section .case-category-content .case-type-tag:contains('${selectedCaseTypeName}')`).closest('.case-category-content').removeClass('hidden');

  // 點擊事件：點擊時設置相應選項並顯示對應的內容
  $('.partner-section .cases-type').on('click', function() {
    $('.cases-type.selected').removeClass('selected');
    $(this).addClass('selected');

    let selectedCaseTypeName = $(this).text().trim(); // 獲取當前選中的標籤文字
    $('.partner-section .case-category-content').addClass('hidden');
    $(`.partner-section .case-category-content .case-type-tag:contains('${selectedCaseTypeName}')`).closest('.case-category-content').removeClass('hidden');
  });

  // 產品內頁 返回前頁功能
  const returnBtn = $('.return');
  returnBtn.on('click', function(){
    window.history.back();
  });

  // 首頁 滾回頂端
  const backToTop = $('#scrollToTop');

  if(backToTop.length) {
    $(window).on('scroll', () => {
      if ($(window).scrollTop() > 400) {
        backToTop.removeClass('hidden'); // 顯示按鈕
      } else {
        backToTop.addClass('hidden'); // 隱藏按鈕
      }
    });
    // 點擊時滾回
    backToTop.on('click', function(e){
      e.preventDefault();
      $('html, body').animate(
        {
          scrollTop: 0,
        },
      );
    });
  }

  // 關於我們 - 側欄 Scrollspy 及 點擊滑動
  if ($('#sideMenu').length == 1) { // 當在有需要監聽側欄的頁面再執行函式
    setupScrollSpy(document.getElementById('sideMenu'));
  }

  // 當產品內頁無檔案時，隱藏「檔案下載」區塊
  const downloadSection = $('#downloadSection');
  const productFiles = downloadSection.find('.product-files .product-file');

  let hasRealContent = false;

    productFiles.each(function () {
        const contentText = $(this).text().trim();  // 取得純文字
        const contentHtml = $(this).html().trim();  // 取得HTML內容

        // 確保沒有實際內容，且 HTML 內沒有有效標籤
        if (contentText.length > 0 || contentHtml.length > 0) {
            hasRealContent = true;
        }
    });

    if (!hasRealContent) {
        downloadSection.addClass('hidden');
    }
});

// 關於我們 Scrollspy
function setupScrollSpy(element) {
  const links = Array.from(element.querySelectorAll('a[href^="#"]'));
  const sections = Array.from(document.querySelectorAll('[data-section-id]'));

  // 建立 IntersectionObserver 偵測哪些區塊（sections）進入視窗可視範圍。
  const observer = new IntersectionObserver(onIntersection, {
    threshold: [0.3]
    // 設定該區塊在 百分之多少為 visible 時，觸發回呼函式 onIntersection
  })

  sections.forEach(section => {
    observer.observe(section)
  })

  // 建立 IntersectionObserver 來監視內容區塊
  function onIntersection(entries) {
    entries.forEach(entry => {
      if(entry.isIntersecting) {
        const section = entry.target
        const sectionId = `#${section.dataset.sectionId}`
        const link = links.find(
          (link) => link.getAttribute('href') === sectionId
        )
        highlightLink(link)
      }
    })
  }

  links.forEach((link) => {
    link.addEventListener('click', (e) => {
      e.preventDefault(); // 防止瀏覽器預設點擊後自動跳至該區塊

      const sectionId = link.getAttribute('href'); // 從側欄連結存取每個區塊對應的連結id
      history.pushState(null, null, sectionId)
      const section = sections.find(section => `#${section.dataset.sectionId}` === sectionId)

      window.scrollTo({
        top: section.offsetTop,
        behavior: 'smooth'
      })
    })
  })

  function highlightLink(targetLink){
    links.forEach(link => {
      if (link === targetLink) {
        link.classList.add('selected')
      } else {
        link.classList.remove('selected')
      }
    })
  }
}
